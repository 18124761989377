import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import LanguageSelector from "../embeddedSelectors/languageSelector";
import { req_join_session } from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";


export default class ChangeChatLanguage extends Component {

    state = {
        open: true,
        conversationType: "join",
        sessionCode: "",
        language: "",
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({language: this.props.myLanguage || "en-US"});
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({language: lang});
    }



    updateSession = () => {

        console.log(this.state.language, localStorage.getItem("cSessionId"))
        let self = this;


        eventBus.dispatch("showLoader", {showLoader: true})
        req_join_session(this.props.sessionCode, this.state.language).then((response) => {
            console.log("req_join_session response", response);
            if (response.status === 200) {
                self.setState({language: response.data.language})
                self.handleClose();
                eventBus.dispatch("updateHeader", {rerun: false});
                eventBus.dispatch("showLoader", {showLoader: false})

            } else {
                alert("Invalid session code", localStorage.getItem("cSessionId"));
                eventBus.dispatch("showLoader", {showLoader: false})
            }
        })

    }



    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',

                }}
            >
                <DialogTitle>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        {content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].description}
                    </DialogContentText>


                    {this.state.conversationType ?
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                              label={content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].languageSelectLabel} mutliSelect={false}/>
                        </div>
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                    <Button variant={"outlined"} onClick={this.updateSession}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
