
export function setLocalStorageVals(userObj) {}

export function createChatSession(sessionId) {
    localStorage.setItem("cSessionId", sessionId);
}

export function createChatSessionUser(userId) {
    localStorage.setItem("cSessionUserId", userId);
}

export function createSelectedAccount(selectedAccountId) {
    //console.log("createSelectedAccount", selectedAccountId);
    localStorage.setItem("selectedAccount", selectedAccountId);
}

export function clearChatSession() {
    localStorage.setItem("cSessionId", "");
}

export function clearChatSessionUser() {
    localStorage.setItem("cSessionUserId", "");
}

export function clearSelectedAccount() {
    localStorage.setItem("selectedAccount", "");
}

export function setSuppressRestartState(val) {
    localStorage.setItem("suppressRestart", val.toString());
}

export function setListeningState(val) {
    localStorage.setItem("listeningState", val.toString());
}

export function setOtherInvokerState(val) {
    localStorage.setItem("otherInvoker", val.toString());
}

export function getSuppressRestartState() {
    return localStorage.getItem("suppressRestart") === "true";
}

export function getListeningState() {
    return localStorage.getItem("listeningState") === "true";
}

export function getMicContious() {
    return localStorage.getItem("micContinuous") === "true";
}


export function getOtherInvokerState() {
    return localStorage.getItem("otherInvoker") === "true";
}




export function languageEval(lang) {
    if (lang === "en") {
        return "en-US"
    } else if (lang === "es") {
        return "es-ES"
    }  else if (lang === "fr") {
        return "fr-FR"
    }
}

