import React, {Component} from "react";
import {IconButton} from "@mui/material";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import StopIcon from "@mui/icons-material/Stop";


export default class AudioPlayControl extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    render() {
        return (<>
                {this.props.currentAudioId === this.props.messageObject.id + "_" + this.props.messageObject.audioUrl && this.props.activeAudio ?
                    <IconButton style={{padding: "2px", marginRight: "5px", marginLeft: "5px"}} onClick={(e) => {
                        this.props.pauseAudio(this.props.messageObject.id + "_" + this.props.messageObject.audioUrl);
                    }}>
                        <StopIcon size={20}
                                  style={{fill: this.props.currentAudioId === this.props.messageObject.id + "_" + this.props.messageObject.audioUrl && this.props.activeAudio ? "green" : "#a6a799"}}/>
                    </IconButton> :
                    null
                }
                {(this.props.currentAudioId !== this.props.messageObject.id + "_" + this.props.messageObject.audioUrl) ||
                (this.props.currentAudioId === this.props.messageObject.id + "_" + this.props.messageObject.audioUrl && !this.props.activeAudio) ?
                    <IconButton style={{padding: "2px", color: "#a6a799", marginRight: "5px", marginLeft: "5px"}} onClick={(e) => {
                        this.props.playAudio(this.props.messageObject.id, this.props.messageObject.audioUrl);
                    }}>
                        <NotStartedIcon size={20} style={{color: "#a6a799", transform: this.props.direction === "rl" ? "rotate(180deg)" : null}}/>
                    </IconButton> : null
                }
            </>
        );
    }
}
