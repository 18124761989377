import React, { Component } from 'react';
import { IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import StopIcon from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Avatar from '@mui/material/Avatar';
import FeedbackButtons from "../widgets/feedbackButtons";
import "./messageHistory.css"
import AudioPlayControl from "../widgets/audioPlayControl";
import MessageOptions from "../widgets/messageOptions";
import {languageData} from "../../globals/languageData";

export default class MessageHistory extends Component {
    state = {
        audioFiles: {},
        audioPending: [],
        activeAudio: false,
        currentAudioFile: "",
        currentAudioId: "",
        firstLoad: this.props.firstLoad,
        activeMessages: [],
        activeTranslations: {},
        translationTab: 0,
        activePanels: {}
    }


    generateAudio = (message, autoplay = false) => {


        //console.log("in gerate audio", message, autoplay)
        let audioFiles = this.state.audioFiles;
        if (!this.state.audioFiles.hasOwnProperty(message.messageId) && !this.state.audioPending.includes(message.messageId)) {
            let audioPending = this.state.audioPending;
            audioPending.push(message.messageId)
            let self = this;
            let audioUrl = "";

            if (["conversation", "quickConnect"].includes(this.props.detailObj.sessionType))  {
                if (message.hasOwnProperty("translationResponse") && message.translationResponse.length > 0) {
                    message.translationResponse.forEach((translation) => {
                        if (translation.language === this.props.myLanguage) {
                            audioUrl = translation.audioUrl;
                        }
                    })
                }
            }

            if (["translate", "shared"].includes(this.props.detailObj.sessionType)){
                //console.log("translate", message)
                if ( this.props.detailObj.sessionType === "translate" && message.hasOwnProperty("translationResponse") && message.translationResponse.length > 0) {
                    message.translationResponse.forEach((translation) => {
                        if (translation.language !== this.props.myLanguage) {
                            audioUrl = translation.audioUrl;
                        }
                    })
                } else if (this.props.detailObj.sessionType === "shared" && message.hasOwnProperty("translationResponse") && message.translationResponse.length > 0){
                    message.translationResponse.forEach((translation) => {
                        if (translation.language !== message.language) {
                            audioUrl = translation.audioUrl;
                        }
                    })
                }
            }

            if (audioUrl !== "") {

                audioFiles[message.messageId] = {
                    messageId: message.messageId,
                    fileData: null,
                    text: message.message,
                    url: audioUrl
                }
                self.setState({audioFiles: {...audioFiles} })
                if (autoplay) {
                    //console.log("autoplay")
                    self.playAudio(message.messageId, audioUrl)
                }
            } else {
                //console.log("in else")
            }
        }
    }

    playAudio = (messageId, audioUrl = "") => {

        try {
            this.setState({
                currentAudioFile: audioUrl,
                currentAudioId: messageId + "_" + audioUrl,
                activeAudio: true
            })
            let audio = document.getElementById("audioPlayer");
            audio.src = audioUrl;
            //console.log("audio", audio)
            audio.muted = false;
            audio.autoPlay = true;
            audio.play();

            audio.onEnded = () => {
                console.log("audio ended");

            };
        } catch (e) {
            console.log("error", e)
        }
    }

    pauseAudio = (messageId) => {
        //console.log("pauseAudio", messageId)
        let audio = document.getElementById("audioPlayer");
        audio.pause();
        this.setState({activeAudio: false})
    }

    resetAudio = () => {
        //console.log("resetAudio")
        this.setState({activeAudio: false})
    }

    MessageBlock = (props) => {
        //console.log(this.props.detailObj)
        //console.log(props.message)
        if (props.message.hasOwnProperty("isDeleted") && props.message.isDeleted === true) {
            return null
        } else {
            if (props.message.sender === "user") {
                //return this.promptBubble(props.message)
                //return this.responseNull();
                return null
            } else if (props.message.sender === "userFunc" || props.message.sender === "sessionFunc") {
                return this.userFuncBubble(props.message)
            } else if (props.message.sender === "userFunc") {
                return this.userFuncBubble(props.message)
            } else if (this.props.detailObj.sessionType === "conversation"
                || this.props.detailObj.sessionType === "translate"
                || this.props.detailObj.sessionType === "quickConnect"
                || this.props.detailObj.sessionType === "shared"
            ) {



                /*
                console.log((props.index === (props.length - 1)
                    && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true)
                    && ((localStorage.getItem("cSessionUserId") !== props.message.senderId) || this.props.detailObj.sessionType !== "conversation")
                    && !this.state.firstLoad
                    && ((localStorage.getItem("uid") !== props.message.sender) || this.props.detailObj.sessionType === "shared")
                ))

                */

                this.generateAudio(props.message,
                    (props.index === (props.length - 1)
                        && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true)
                        && ((localStorage.getItem("cSessionUserId") !== props.message.senderId) || this.props.detailObj.sessionType !== "conversation")
                        && !this.state.firstLoad
                        && ((localStorage.getItem("uid") !== props.message.sender) || this.props.detailObj.sessionType === "shared")
                    ), (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true))

                //return this.responseNull();
                return this.compositeBubble(props.message)
                //}
            } else {
                return this.responseBubble(props.message, props.index, props.length)
            }
        }
    }

    responseNull = () => {
        return (<div key={"null"} style={{display: "none"}}></div>)
    }


    activatePanel = (initialId, panelId) => {
        let activePanels = this.state.activePanels;
        activePanels[initialId] = {"active": panelId};
        this.setState({activePanels: activePanels})
    }

    responseBubble = (message, ind, len) => {
        //console.log("response message", message)
        this.generateAudio(message, (ind === (len - 1)
            && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true) )
            && ((localStorage.getItem("cSessionUserId") !== message.senderId) || this.props.detailObj.sessionType !== "conversation")
            && !this.state.firstLoad)
        if (message.hasOwnProperty("message") && message.message !== "") {
            return (<div key={message.messageId}
                         style={{
                             padding: "5px",
                             borderRadius: "5px",
                             borderTopLeftRadius: "0",
                             borderTopRightRadius: "0",
                             textAlign: "left",
                             display: "flex",
                             flexDirection: "row",
                             border: "1px solid #ddd",
                             backgroundColor: "#f7f7f7",
                             marginBottom: "15px",
                             fontSize: ".8rem",
                             marginLeft: "10px",
                             marginRight: "5px",
                             color: "#494949"
                         }}
                         className={"chat chat-ai"}>
                <div style={{width: "50px", fontSize: ".7rem"}}>[{message.translatedTo || message.language}]</div>
                <div key={message.messageId} style={{width: "100%"}}
                     dangerouslySetInnerHTML={{__html: message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />').replaceAll("\n", '<br />')}}/>

                <div style={{borderRadius: "20px", width: "40px", textAlign: "right"}}>
                    {this.state.audioFiles.hasOwnProperty(message.messageId) ?
                        <div style={{textAlign: "right"}}>
                            {this.state.currentAudioId === message.messageId + "_" + message.audioUrl && !this.state.activeAudio ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                   this.playAudio(message.messageId, message.audioUrl);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId === message.messageId + "_" + message.audioUrl && this.state.activeAudio ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                    this.pauseAudio(message.messageId + "_" + message.audioUrl);
                                }}>
                                    <StopIcon size={20}
                                              style={{fill: this.state.currentAudioId === message.messageId + "_" + message.audioUrl && this.state.activeAudio ? "green" : "inherit"}}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId !== message.messageId + "_" + message.audioUrl ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                    this.playAudio(message.messageId, message.audioUrl);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> : null
                            }
                        </div>
                        : <CircularProgress size={20}/>
                    }
                </div>


            </div>)
        }
    }



    userFuncBubble = (message) => {
        return (<div className={"chat chat-human"} style={{padding: "3px", borderRadius: "10px", border: "1px dashed #aaa", color: "#555", marginLeft: "60px", marginRight: "60px"}}>
            <ChangeCircleIcon style={{fontSize: "20px", marginRight: "10px", marginTop: "5px"}} /><div style={{display: "inline-block", paddingTop: 0, marginBottom: "5px"}} key={message.messageId} dangerouslySetInnerHTML={{__html:message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}} /></div>)
    }


    setPrimaryBorderColor = (message) => {

        if (this.props.detailObj.sessionType === "shared" && message.language === this.props.myLanguage){
            return "#30afcd"
        }

        if (this.props.detailObj.sessionType === "shared" && message.language !== this.props.myLanguage){
            return "#e16a09"
        }


        if (this.props.detailObj.sessionType !== "shared" &&
            this.props.detailObj.hasOwnProperty("userProps") &&
            this.props.detailObj.userProps.hasOwnProperty(message.senderId) &&
            this.props.detailObj.userProps[message.senderId].hasOwnProperty("avatarColor") &&
            this.props.detailObj.userProps[message.senderId].avatarColor){
            return this.props.detailObj.userProps[message.senderId].avatarColor
        }




        return "#e16a09"

    }

    setPrimaryBgColor = (message) => {

        if (this.props.detailObj.sessionType === "shared" && message.language === this.props.myLanguage){
            return "#f5fdff"
        }

        if (this.props.detailObj.sessionType === "shared" && message.language !== this.props.myLanguage){
            return "#fffbf9"
        }

        if (this.props.detailObj.sessionType !== "shared"){
            return "#fcfcfc"
        }

            return "#fffbf9"
    }


    compositeBubble = (message) => {
        //console.log(message)
        //console.log("message", message.feedback)
        let primaryObject = {};
        let remainingObjectArray = [];

            primaryObject = {
                language: message.language,
                response: message.message,
                audioUrl: "",
                id: ""
            }

        let cloneMessage = {...message};
        if (message.hasOwnProperty("translationResponse") && Array.isArray(message.translationResponse)){

            let tResponse = message.translationResponse;

            tResponse.forEach((translation) => {
                //console.log("translation", translation)
                //console.log("primaryObject", primaryObject)


                if (this.props.detailObj.sessionType === "shared"){
                if ( message.language !== this.props.myLanguage && this.props.detailObj.sessionType === "shared"){
                        primaryObject = translation;
                    }
                } else {
                    if ( translation.language === this.props.myLanguage ){
                        //console.log("1st", translation)
                        primaryObject = translation;
                    }
                }

            })

            if (this.props.detailObj.sessionType === "shared"){
                tResponse.forEach((translation) => {
                    if (translation.language === primaryObject.language) {
                        //console.log("2nd", translation)
                        primaryObject.audioUrl = translation.audioUrl;
                        primaryObject.id = translation.id;
                    } else {
                        //console.log("3rd", translation)
                        remainingObjectArray.push(translation);
                    }
                })
            } else {

                tResponse.forEach((translation) => {
                    if (translation.language === primaryObject.language) {
                        //console.log("2nd", translation)
                        primaryObject.audioUrl = translation.audioUrl;
                        primaryObject.id = translation.id;
                    } else {
                        //console.log("3rd", translation)
                        remainingObjectArray.push(translation);
                    }
                })
            }


            if (primaryObject.hasOwnProperty("response")
                && primaryObject.response
                && primaryObject.response !== ""
                && primaryObject.response !== null) {
                primaryObject.response.normalize();
                primaryObject.response.replaceAll(/(\r\n|\r|\n)/g, '<br />')
            } else {
                primaryObject.response = "";
            }
        }



        return (
            <div key={"container_" + message.messageId}>

                <div className={"compositePrimaryContainer"}

                style={{
                    borderWidth: "1px",
                    boxShadow: "1px 1px #ddd",
                    borderColor: this.setPrimaryBorderColor(message),
                    backgroundColor: this.setPrimaryBgColor(message),
                }}

                >

                    {this.props.detailObj.sessionType !== "shared" ?
                        <Avatar
                            sx={{
                                width: 26, height: 26,
                                position: "absolute", left: "-10px",
                                top: "5px", fontSize: ".8rem",
                                border: "1px solid #888",
                                backgroundColor:
                                    this.props.detailObj.hasOwnProperty("userProps") &&
                                    this.props.detailObj.userProps.hasOwnProperty(message.senderId) &&
                                    this.props.detailObj.userProps[message.senderId].hasOwnProperty("avatarColor") &&
                                    this.props.detailObj.userProps[message.senderId].avatarColor ?
                                        this.props.detailObj.userProps[message.senderId].avatarColor
                                        : "#5a596c"
                            }}

                            src={
                                this.props.detailObj.hasOwnProperty("userProps") &&
                                this.props.detailObj.userProps.hasOwnProperty(message.senderId) &&
                                this.props.detailObj.userProps[message.senderId].hasOwnProperty("avatarColor") &&
                                this.props.detailObj.userProps[message.senderId].avatarUrl ?
                                    "https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/" + this.props.detailObj.userProps[message.senderId].avatarUrl.replace("/", "%2F") + "?alt=media" : null}
                        >{message.senderDisplayName[0] || ""}</Avatar> : null
                    }

                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: languageData[primaryObject.language]["direction"] === "rl" ? "row-reverse" : "row"}}>

                        <div style={{ display: "flex", padding: "20px 5px 15px 5px", width: "100%",
                            flexDirection: languageData[primaryObject.language]["direction"] === "rl" ? "row-reverse" : "row",
                            marginLeft: "15px", textAlign: "left"}}>

                            {languageData[primaryObject.language]["direction"] === "rl" ?
                                <div style={{
                                    minWidth: "70px",
                                    padding: "5px 0 0 20px",
                                    fontSize: ".7rem",
                                    display: "inline-block",
                                    textAlign: "right"
                                }}>[ {primaryObject.language} ]</div>
                                : <div style={{
                                    minWidth: "70px",
                                    padding: "5px 20px 0 0px",
                                    fontSize: ".7rem",
                                    display: "inline-block"
                                }}>[ {primaryObject.language} ]</div>
                            }


                            {languageData[primaryObject.language]["direction"] === "rl" ?
                                <div style={{fontSize: "1rem", textAlign: "right"}}>
                                    <AudioPlayControl currentAudioId={this.state.currentAudioId}
                                                      messageObject={primaryObject}
                                                      activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                                      playAudio={this.playAudio}
                                                      direction={"rl"}
                                    />{primaryObject.response}
                                </div> :
                                <div style={{fontSize: "1rem", textAlign: "left"}}>{primaryObject.response}
                                    <AudioPlayControl currentAudioId={this.state.currentAudioId}
                                                      messageObject={primaryObject}
                                                      activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                                      playAudio={this.playAudio}
                                                      direction={"lr"}
                                    />
                                </div>
                            }
                        </div>

                        <div style={{position: "absolute", color: "#111", top: "0", padding: "0px 6px 2px 5px", fontSize: ".7rem", marginLeft: "20px"}}>
                            { (this.props.detailObj.sessionType !== "translate" && this.props.detailObj.sessionType !== "shared" ? message.senderDisplayName + ": " : "") + new Date(message.sent).toLocaleString()}
                        </div>

                        <div style={{display: "flex", alignItems: "flexStart", marginLeft: languageData[primaryObject.language]["direction"] === "rl" ? "20px" : "0"}}>
                            <FeedbackButtons messageId={message.initialId} sessionId={this.props.detailObj.id}
                                             feedback={message.feedback || ""} feedbackExplanation={message.feedbackExplanation || ""} />
                            { message.senderId === localStorage.getItem("uid") ?
                            <MessageOptions messageId={message.initialId} sessionId={this.props.detailObj.id} />
                                : null
                            }
                        </div>
                    </div>
                </div>

                <div className={"compositeSecondaryContainer"} style={{
                    border: this.state.activePanels.hasOwnProperty(message.initialId) || this.props.detailObj.sessionType === "translate" || this.props.detailObj.sessionType === "shared" ? "1px solid #ddd" : "none",
                    backgroundColor: this.state.activePanels.hasOwnProperty(message.initialId) || this.props.detailObj.sessionType === "translate" || this.props.detailObj.sessionType === "shared" ? "#f7f7f7" : "transparent",
                }}>

                {remainingObjectArray.map((translation, index) => {
                    return (<div key={"sub_" + translation.id} className={"translationContainer"} style={{
                            display: (this.props.myLanguage !== translation.language) && (this.props.detailObj.sessionType !== "translate" && this.props.detailObj.sessionType !== "shared") ? "inline-block" : "none",
                            backgroundColor: this.state.activePanels.hasOwnProperty(message.initialId) && this.state.activePanels[message.initialId].active === translation.id ? "#627389" : "#f7f7f7",
                            color: this.state.activePanels.hasOwnProperty(message.initialId) && this.state.activePanels[message.initialId].active === translation.id ? "#fff" : "inherit",

                        }}  index={translation.id} label={translation.language} onClick={() => {

                            this.activatePanel(message.initialId, translation.id)}
                        }>{translation.language}
                        {translation.language === message.language ? <b>*</b> : null}
                    </div>)
                    })

                }

                {this.props.detailObj.sessionType === "conversation" || this.props.detailObj.sessionType === "quickConnect" || this.props.detailObj.sessionType === "translate" || this.props.detailObj.sessionType === "shared"  ?
                    <div style={{display: "block"}}>
                        {remainingObjectArray.map((translation, index) => {

                            return (<div key={translation.id} index={index}
                                         value={this.state.translationTab}
                                         className={"translationTab"}
                                         label={languageData}
                                         style={{
                                             display: (this.state.activePanels.hasOwnProperty(message.initialId) &&
                                             this.state.activePanels[message.initialId].active === translation.id) || this.props.detailObj.sessionType === "translate" || this.props.detailObj.sessionType === "shared" ? "flex" : "none",
                                             flexDirection: languageData[translation.language || "en-US"]["direction"] === "rl" ? "row-reverse" : "row",
                                         }}>
                                {this.props.detailObj.sessionType === "translate" || this.props.detailObj.sessionType === "shared" ?
                                    <div style={{width: "70px", padding: "5px 10px 0 10px", fontSize: ".7rem", textAlign: languageData[translation.language || "en-US"]["direction"] === "rl" ? "right" : "left"
                                    }}>[{translation.language || "en-US"}]</div>
                                    : null
                                }

                                {languageData[translation.language || "en-US"]["direction"] === "rl" ?
                                    <div style={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        paddingTop: "5px",
                                        textAlign: "right"
                                    }}>
                                        <AudioPlayControl
                                            currentAudioId={this.state.currentAudioId} messageObject={translation}
                                            activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                            playAudio={this.playAudio} direction={"rl"}
                                        />
                                        {translation.response} </div> :

                                    <div style={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        paddingTop: "5px"
                                    }}>{translation.response} <AudioPlayControl
                                        currentAudioId={this.state.currentAudioId} messageObject={translation}
                                        activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                        playAudio={this.playAudio}  direction={"lr"}
                                    /></div>
                                }


                            </div>)
                        })}
                    </div> : null
                }

                </div>
            </div>)
    }



    UnsetFirstLoad = () => {

        let messageIds = [];
        let activeTranslationIds = []
        this.props.messages.forEach((message) => {
            if (message.sender === "user"){
                messageIds.push(message.messageId)
            }

            if (message.sender !== "user" && message.initialId) {

                if (!activeTranslationIds.hasOwnProperty(message.initialId)) {
                    activeTranslationIds[message.initialId] = {
                        "translations": message.translationResponse
                    };
                } else {
                    activeTranslationIds[message.initialId].translations = message.translationResponse
                }
            }
        })


        this.setState({activeMessages: messageIds, activeTranslations: activeTranslationIds, firstLoad: false})
        return (<></>)
    }

    render() {
        //console.log("messages", this.props.messages)
        return (<>

            <audio id="audioPlayer" controls autostart="0" onEnded={this.resetAudio} style={{width: 150, height: 25, display: "none"}}>
                {this.state.hasOwnProperty("currentAudioFile") && this.state.currentAudioFile !== "" ?
                  <source
                    src={this.state.currentAudioFile}
                    type="audio/mp3" />
                  : null
                }
            </audio>
            {this.props.messages.map((message, index) => {
                return <this.MessageBlock key={message.messageId} message={message} index={index} length={this.props.messages.length} />
            })}

            {this.state.firstLoad ? <this.UnsetFirstLoad /> : null}
        </>)
    }
}
