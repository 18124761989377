import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import Link from "@mui/material/Link";
import {req_delete_session} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";

export default class ChatDelete extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    deleteSession = () => {
        console.log("delete session", this.props.chatObj)
        req_delete_session(this.props.chatObj.id).then((result) => {
            if (result.status === 200) {
                eventBus.dispatch("updateSessions", {});
                this.props.clearSession();
                this.handleClose();
            } else {
                this.handleClose();
            }
        })
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>{content.chatDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        {content.chatDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].textContent}
                    </DialogContentText>



                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.chatDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButtonText}</Button>
                    <Button variant={"outlined"} onClick={this.deleteSession}>{content.chatDeletePanel[localStorage.getItem("siteLanguage") || "en-US"].deleteButtonText}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
