import React, { Component } from "react";
import { db } from "../services/firebase"
import eventBus from "./eventBus";

export default class Listener extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: localStorage.getItem("rtoken"),
            messages: [],
            readError: null,
            writeError: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.myRef = React.createRef();
    }

    sendEvent = (message) => {
        //console.log("sending event", message)

        let pushEvent = message.split(":");

        //if (pushEvent[0] === "updateAssetMenu" && JSON.parse(localStorage.getItem("selectedAccount")) === pushEvent[0]){

        if (pushEvent[0] === "updateSessions"){
            eventBus.dispatch(pushEvent[0], {  });
        }

        else if (pushEvent[0] === "deleteMessage"){
            //console.log("edata", pushEvent[1])
            let cvals = pushEvent[1].split("_")
            if (localStorage.getItem("cSessionId") === cvals[0]) {
                eventBus.dispatch(pushEvent[0], {messageId: cvals[1] });
            }
        }
        /*
        else if (pushEvent[0] === "updateUserMenu"){
            eventBus.dispatch(pushEvent[0], {  });
        } else if (pushEvent[0] === "updateNotifications"){
            eventBus.dispatch(pushEvent[0], {  });
        }
        */
    }

    async componentDidMount() {
        this.setState({ readError: null });
        try {
            db.ref("/updStream/" + localStorage.getItem("uid")).on("value", snapshot => {
                let messages = [];
                //console.log("receiving user update")
                if (snapshot.val()){
                    //console.log(snapshot.val())
                    messages.push(snapshot.val());
                    messages.forEach((message) =>{ this.sendEvent(message) })
                    this.setState({ messages });
                }
            });

        } catch (error) {
            this.setState({ readError: error.message });
        }
    }

    handleChange(event) {}

    render() { return ( <span style={{display: "none"}} /> ); }
}
