import * as React from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import {useEffect} from "react";
import {req_user_account_get} from "../../helpers/requests";
import logo from "../../assets/Logo2.png";
import {LinearProgress} from "@mui/material";

export default function AcctVerify() {

    const navigate = useNavigate();

    const verifyAccount = () => {

        req_user_account_get(localStorage.getItem("uid")).then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("plan", result.data.data.plan ? result.data.data.plan : "");
                navigate('/');
            } else {
                //self.setState({loading: false, alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    };

    useEffect(() => {
        verifyAccount()
    },[])

    return (

        <div>
            <div style={{marginTop: "10px"}}><img src={logo} alt={process.env.REACT_APP_BUSINESS_NAME + " Logo"} style={{maxWidth: "480px"}} /></div>
            Thank you!<br /><br />
            We're setting up you plan now!<br /><br />
            <LinearProgress />
        </div>
    );
}
