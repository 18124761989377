import React, {Component} from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {content} from "../../globals/content";
import CardActions from "@mui/material/CardActions";
import {Button, ListItem, ListItemText, List, Tooltip, AvatarGroup, IconButton} from "@mui/material";
import Card from "@mui/material/Card";

import Avatar from "@mui/material/Avatar";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TranslateIcon from "@mui/icons-material/Translate";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AddCircleIcon from "@mui/icons-material/AddCircle";


export default class SessionCard extends Component {




    joinSession = (session) => {
        let lang = "";
        session.sessionMembers.forEach((member) => {
            if (member.userId === localStorage.getItem("uid")){
                console.log("found")
                lang = member.language;
            }
        })

        this.props.joinSession(session.id, lang, session)
    }

    render() {
        return (
            <Card sx={{ minWidth: 265, maxWidth: 350, backgroundColor: "#f5f5f5" }}>
                <CardContent style={{paddingTop: "15px", paddingBottom: "15px"}}>

                    <div style={{fontSize: "1rem", fontWeight: "500"}}>
                        {this.props.sessionType === "conversation" &&
                        <QuestionAnswerIcon style={{fontSize: "18px", marginRight: 10}} /> }
                        {this.props.sessionType === "shared" &&
                        <InterpreterModeIcon style={{fontSize: "18px", marginRight: 10}} /> }
                        {this.props.sessionType === "translate" &&
                        <TranslateIcon style={{fontSize: "18px", marginRight: 10}} /> }
                        {this.props.sessionType === "quickConnect" &&
                        <QrCodeScannerIcon style={{fontSize: "18px", marginRight: 10}} /> }

                        {this.props.title}
                        <IconButton onClick={this.props.openNew}><AddCircleIcon style={{fontSize: "20px", color: "#df5f41"}} /></IconButton>
                    </div>

                    <List dense={true}

                          sx={{
                                width: '100%',
                              backgroundColor: 'transparent',
                              // hover states
                              '& .MuiListItem-root': {
                                  bgcolor: 'transparent',
                              },
                              '& .MuiListItem-root:hover': {
                                  bgcolor: '#fff',
                              },
                          }}

                    >
                        {this.props.sessions.filter((session) => session.sessionType === this.props.sessionType).map((session) => {
                            return <ListItem alignItems="flex-start" style={{padding: "3px", margin: "0", borderBottom: "1px solid #ddd", cursor: "pointer"}}
                                             onClick={
                                                 () => {this.joinSession(session)}
                                             }>
                                <ListItemText style={{margin: 1}} primary={
                                    <React.Fragment>
                                        <div style={{display: "flex", flexDirection: "row",
                                            justifyContent: "space-between"}}>

                                        <b>{session.name}</b>
                                            {this.props.sessionType !== "translate" ?
                                        <AvatarGroup sx={{
                                            '& .MuiAvatar-root': {
                                                boxSizing: "border-box",
                                                width: 24,
                                                height: 24,
                                                fontSize: ".7rem",
                                            },
                                        }}
                                                     max={5}>
                                            {session.sessionMembers.map((member) => {
                                                return (<Tooltip key={member.userId} title={member.displayName}><Avatar
                                                    key={member.userId}
                                                    sx={{

                                                        bgcolor: session.hasOwnProperty("userProps")
                                                        && session.userProps.hasOwnProperty(member.userId)
                                                        && session.userProps[member.userId].hasOwnProperty("avatarColor")
                                                            ? session.userProps[member.userId].avatarColor : "#eee",

                                                        width: 24,
                                                        height: 24,
                                                        fontSize: ".7rem",
                                                        color: "#fff",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        display: "flex"

                                                    }}

                                                    src={session.hasOwnProperty("userProps")
                                                    && session.userProps.hasOwnProperty(member.userId)
                                                    && session.userProps[member.userId].hasOwnProperty("avatarUrl")
                                                        ?
                                                        "https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/" + session.userProps[member.userId].avatarUrl.replace("/", "%2F") + "?alt=media"
                                                        : null}
                                                    alt={member.displayName}
                                                >{member.displayName[0]}</Avatar></Tooltip>)
                                            })
                                            }
                                        </AvatarGroup> : null
                                            }
                                        </div>


                                    </React.Fragment>}
                                              secondary={
                                                  <React.Fragment>
                                                      <div
                                                          style={{ color: 'text.primary', display: 'block', fontSize: ".7rem", fontWeight: "bold" }}
                                                      >
                                                          Languages: {session.languages.join(", ")}
                                                      </div>
                                                  </React.Fragment>
                                              }

                                >
                                </ListItemText>
                            </ListItem>
                        })
                        }


                    </List>
                </CardContent>

            </Card>
        );
    }

}
