import React, {Component, useCallback, useState} from "react";
import getCroppedImg, { readFile } from "../../helpers/cropImage";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Cropper from "../cropper/cropper";
import { RotationSlider, ZoomSlider } from "../cropper/sliders";
import {content} from "../../globals/content";
import eventBus from "../../helpers/eventBus";

const ImageCropper = (props) => {


    const defaultImage = null;
    const defaultCrop = { x: 0, y: 0 };
    const defaultRotation = 0;
    const defaultZoom = 1;
    const defaultCroppedAreaPixels = null;

    const [image, setImage] = useState(defaultImage);
    const [crop, setCrop] = useState(defaultCrop);
    const [rotation, setRotation] = useState(defaultRotation);
    const [zoom, setZoom] = useState(defaultZoom);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(defaultCroppedAreaPixels);

    const max_zoom = 3;
    const min_zoom = 1;
    const zoom_step = 0.1;
    const max_rotation = 360;
    const min_rotation = 0;
    const rotation_step = 5;

    const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
        console.log("h1")
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const onCropChange = useCallback((_croppedArea, croppedAreaPixels) => {
        console.log("h2", _croppedArea, croppedAreaPixels)
        setCrop(_croppedArea);
    }, []);

    const handleZoomIn = () => {
        if (zoom < max_zoom) {
            setZoom(zoom + zoom_step * 2);
        }
    };

    const handleZoomOut = () => {
        if (zoom > min_zoom) {
            setZoom(zoom - zoom_step * 2);
        }
    };

    const handleRotateCw = () => {
        setRotation(rotation + rotation_step);
    };

    const handleRotateAntiCw = () => {
        setRotation(rotation - rotation_step);
    };

    const handleFileChange = async ({ target: { files } }) => {
        console.log("handleFileChange")
        const file = files && files[0];
        if (file) {
            console.log("has file")
            const imageDataUrl = await readFile(file);
            setImage(imageDataUrl);
        }
    };

    const getProcessedImage = async () => {
        if (image && croppedAreaPixels) {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
            console.log(croppedImage)
            const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
                type: 'image/png'
            });
            return croppedImage;
        }
    };


    const saveAvatar = () => {
        console.log("saveAvatar")

        let pprops = props;
        getProcessedImage().then((imageFile) => {
            console.log(imageFile);
            props.save(imageFile);
            handleClose();

        })



    }


    const handleClose = () => {
        //setOpen(false);
        props.close()
    }


    const onDrag = (e) => {
        console.log("dragging", e)
    }

    /*
    const onMouseMove = (e) => {
        if (isDragging) {
            // Calculate new crop position based on mouse movement
            const newX = crop.x + e.movementX / zoom;
            const newY = crop.y + e.movementY / zoom;

            setCrop({ x: newX, y: newY });
        }
    };

     */

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    handleClose();
                },
            }}
        >
            <DialogContent style={{padding: "10px"}}>
                <DialogTitle>{content.uploadAvatarPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>



                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                    <div className="crop-container mb-4">

                            <div style={{position: "relative", width: "250px", height: "250px"}}>
                                <Cropper onCropComplete={onCropComplete}

                                         onZoomChange={setZoom}
                                         image={image}
                                         setImage={setImage}
                                         crop={crop}
                                         setCrop={setCrop}
                                         rotation={rotation}
                                         zoom={zoom}
                                         croppedAreaPixels={croppedAreaPixels}
                                         setCroppedAreaPixels={setCroppedAreaPixels}
                                         max_zoom={max_zoom}
                                         min_zoom={min_zoom}
                                         zoom_step={zoom_step}
                                         max_rotation={max_rotation}
                                         min_rotation={min_rotation}
                                         onCropChange={onCropChange}
                                         setRotation={setRotation}
                                         setZoom={setZoom}
                                         getCroppedImg={getCroppedImg}
                                         onDrag={onDrag}
                                         getProcessedImage={getProcessedImage}
                                         handleZoomIn={handleZoomIn}
                                         handleZoomOut={handleZoomOut}
                                         handleRotateAntiCw={handleRotateAntiCw}
                                         handleRotateCw={handleRotateCw}

                                />
                            </div>

                    </div>
                    </DialogContentText>
                </DialogContent>
                <div style={{margin: "0 auto", textAlign: "center"}}>
                    <ZoomSlider setZoom={setZoom}
                                max_zoom={max_zoom}
                                min_zoom={min_zoom}
                                zoom_step={zoom_step}
                                zoom={zoom}
                                handleZoomIn={handleZoomIn}
                                handleZoomOut={handleZoomOut}
                    />
                    <RotationSlider
                        max_rotation={max_rotation}
                        min_rotation={min_rotation}
                        rotation={rotation}
                        rotation_step={rotation_step}
                        setRotation={setRotation}
                        handleRotateAntiCw={handleRotateAntiCw}
                        handleRotateCw={handleRotateCw}
                    />
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="hidden"
                        id="avatarInput"
                        accept="image/*"
                    />

                    <Button variant="contained" startIcon={<FileUploadIcon />} >

                        <label htmlFor="avatarInput">{content.uploadAvatarPanel[localStorage.getItem("siteLanguage") || "en-US"].uploadButtonText}</label>
                    </Button>
                </div>


            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>{content.uploadAvatarPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButtonText}</Button>
                <Button variant="outlined" onClick={saveAvatar}>{content.uploadAvatarPanel[localStorage.getItem("siteLanguage") || "en-US"].saveButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageCropper;
