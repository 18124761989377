import React, {Component} from "react";
import {IconButton} from "@mui/material";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import StopIcon from "@mui/icons-material/Stop";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {content} from "../../globals/content";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";


export default class LeftMenuBottom extends Component {

    render() {
        return (<div>
                <IconButton aria-describedby={"lmb_1"} variant="contained" onClick={() => {
                    this.props.toggleAccountDrawer("left", true)}
                }>
                    <ManageAccountsIcon style={{color: "#333"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].accountSubtext}</div>

                <IconButton aria-describedby={"lmb_2"} variant="contained" onClick={() => {
                    this.props.toggleSettingsDrawer("right", true)}
                }>
                    <SettingsIcon style={{color: "#333"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].settingsSubtext}</div>

                <IconButton aria-describedby={"lmb_3"} variant="contained" onClick={() => {
                    this.props.infoPanelToggle( true)}
                }>
                    <InfoIcon style={{color: "#333"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].siteInfoSubtext}</div>

            </div>
        );
    }
}
