import React, {Component} from "react";
import Typography from "@mui/material/Typography";
import {
    Button,
    FormControl, FormControlLabel,
    FormLabel, Radio,
    IconButton,
    InputLabel,
    MenuItem,
    OutlinedInput,
    RadioGroup,
    Select
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {content} from "../../globals/content";
import eventBus from "../../helpers/eventBus";
import {req_user_account_get, req_user_settings_put} from "../../helpers/requests";

export default class SettingsPanel extends Component {

    state = {
        defaultLanguage: "",
        voiceGender: "",
        autoplayAudio: true
    }

    setLanguage = (lang) => {
        this.setState({defaultLanguage: lang})
    }

    componentDidMount() {
        this.loadSettings()
    }

    loadSettings = () => {
        let self = this;

        eventBus.dispatch("showLoader", {showLoader: true})
        req_user_account_get(localStorage.getItem("uid")).then(function (result) {
            if (result.status === 200) {

                if (result.data.data.hasOwnProperty("settings")) {
                    console.log(result.data.data)

                    let dLanguage = result.data.data.settings.hasOwnProperty("defaultLanguage") ? result.data.data.settings.defaultLanguage : "en-US";
                    let vGender = result.data.data.settings.hasOwnProperty("voiceGender") ? result.data.data.settings.voiceGender : "female";
                    let aResponse = result.data.data.settings.hasOwnProperty("autoplayAudio") ? result.data.data.settings.autoplayAudio : true;
                    self.setState({ defaultLanguage: dLanguage, voiceGender: vGender, autoplayAudio: aResponse});
                    eventBus.dispatch("showLoader", {showLoader: false})
                }
            } else {
                eventBus.dispatch("showLoader", {showLoader: false})
                //self.setState({ alert:{...{ "open": true, "message": GENERIC_LOAD_ERROR, "type": "error", "timeout": 5000 }}});
            }
        })
    }

    updateSettings = () => {
        let self = this;
        eventBus.dispatch("showLoader", {showLoader: true})
        req_user_settings_put(localStorage.getItem("uid"), {
            defaultLanguage: this.state.defaultLanguage,
            voiceGender: this.state.voiceGender,
            autoplayAudio: this.state.autoplayAudio}).then(function (result)
        {

            localStorage.setItem("siteLanguage", self.state.defaultLanguage);
            eventBus.dispatch("updateSiteLanguage", {  });
            eventBus.dispatch("showLoader", {showLoader: false})
        })

    }

    render() {
        return (
            <div style={{padding: "10px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div><Typography variant="h5">{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].title}</Typography></div><div>

                    <IconButton variant="contained" onClick={() => {
                        this.props.toggleSettingsDrawer("right", false)
                    }
                    }>
                        <CloseIcon />
                    </IconButton>
                </div>
                </div>

                <br />


                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                  label={content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].defaultLanguage} mutliSelect={false}/>

                    <FormControl sx={{ width: 300 }} style={{margin: "10px auto"}}>
                        <InputLabel htmlFor="demo-multiple-name-label">{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].voiceGender}</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            defaultValue={this.state.voiceGender}
                            value={this.state.voiceGender}
                            input={<OutlinedInput
                                label={content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].voiceGender}
                            />}
                            onChange={(e) => {
                                this.setState({voiceGender: e.target.value})
                            }}
                            style={{textAlign: "left"}}

                        >
                            <MenuItem
                                key={"male"}
                                value={"male"}
                            >
                                <div style={{display: "flex", flexDirection: "row"}}>{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].male}</div>
                            </MenuItem>
                            <MenuItem
                                key={"female"}
                                value={"female"}
                            >
                                <div style={{display: "flex", flexDirection: "row"}}>{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].female}</div>
                            </MenuItem>
                        </Select>
                    </FormControl>



                    <FormControl style={{margin: "0 auto", width: 300}}>
                        <FormLabel id="demo-row-radio-buttons-group-label">{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].autoPlay}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                                this.setState({autoplayAudio: e.target.value})
                            }}
                            value={this.state.autoplayAudio}
                        >
                            <FormControlLabel value={true} control={<Radio />} label={content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].yes} />
                            <FormControlLabel value={false} control={<Radio />} label={content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].no} />

                        </RadioGroup>
                    </FormControl>

                    <div style={{display: "flex", gap: 10, width: "300px", justifyContent: "center", margin: "10px auto"}}>
                        <Button variant={"outlined"} onClick={this.updateSettings} style={{width: "145px"}}>{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].save}</Button>
                        <Button variant={"outlined"} style={{width: "145px"}} onClick={() => {
                            this.props.toggleSettingsDrawer("right", false)
                        }}>{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].cancel}</Button>
                    </div>

                </div>
            </div>
        )
    }
}
