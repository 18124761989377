import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {
    req_quick_session_id
} from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";
import QRCode from "react-qr-code";
import CopyAllIcon from "@mui/icons-material/CopyAll";

export default class QuickConnect extends Component {
    state = {
        open: true,
        conversationType: "quickConnect",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
        inviteId: null,
        name: "",
        chatNameError: false
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    componentDidMount() {
        this.setState({language: localStorage.getItem("siteLanguage") || "en-US"});
        this.setState({peerLanguage: localStorage.getItem("siteLanguage") || "en-US"});
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({language: lang});
    }
    setPeerLanguage = (lang) => {
        this.setState({peerLanguage: lang});
    }

    updateSession = () => {
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
            this.setState({chatSessionId: localStorage.getItem("cSessionId"), open: false});
            this.props.updateSession(localStorage.getItem("cSessionId"), this.state.language, {accountId: localStorage.getItem("selectedAccount")});

        } else {
            this.setState({chatSessionId: undefined});
        }
    }

    createSession = (vConversationType, vSessionCode, vLanguage) => {

        let self = this;
        this.setState({chatNameError: false});
        if (this.state.name !== "") {
            if (this.state.conversationType === "quickConnect") {
                console.log("Creating quick connect", this.state.language);
                self.setState({loading: true});

                eventBus.dispatch("updateSessions", {});
                req_quick_session_id(this.state.language, this.state.name).then((response) => {
                    console.log("req_quick_session_id response", response);
                    eventBus.dispatch("updateSessions", {});
                    console.log(response.data.inviteId)
                    this.setState({inviteId: response.data.inviteId});
                    createChatSession(response.data.newSession);
                    createChatSessionUser(response.data.userId);
                    self.updateSession();
                    self.setState({loading: false});
                })

            }
        } else {
            this.setState({chatNameError: true});
        }
    }

    handleCopy = () => {
        console.log("copy")
        navigator.clipboard.writeText("https://earthlingual.com/qc/" + this.state.inviteId);
    }

    render() {
        console.log("props", this.props)
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();

                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>{content.createQuickConnectPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    {!this.state.inviteId ?
                        <>
                            <DialogContentText style={{marginBottom: "15px"}}>
                                {content.createQuickConnectPanel[localStorage.getItem("siteLanguage") || "en-US"].createChatDescription}
                            </DialogContentText>

                            {
                                this.state.conversationType ?
                                    <div style={{width: "100%", margin: "10px auto 0px auto"}}>

                                        <div style={{textAlign: "center"}}>
                                            <TextField value={this.state.name}
                                                       required={true}
                                                       error={this.state.chatNameError}
                                                       label={content.createQuickConnectPanel[localStorage.getItem("siteLanguage") || "en-US"].chatNameLabel}
                                                       onChange={(e) => {
                                                           this.setState({name: e.target.value})
                                                       }} style={{width: "300px"}}
                                            >
                                            </TextField>
                                        </div>
                                    </div>
                                    : null
                            }


                            {this.state.conversationType ?
                                <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                    <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                                      label={content.createQuickConnectPanel[localStorage.getItem("siteLanguage") || "en-US"].myLanguageLabel}
                                                      mutliSelect={false}/>
                                </div>
                                : null
                            }
                        </> : null
                    }


                    {this.state.inviteId ?
                        <div style={{textAlign: "center", display: "flex", flexDirection: "column"}}>
                            <div style={{margin: 10}}>Have a friend scan this QR code or use the link below to chat.</div>
                            <div style={{textAlign: "center"}}>
                            <QRCode value={"https://earthlingual.com/qc/" + this.state.inviteId}/>
                            </div>

                            <div>

                                {"https://earthlingual.com/qc/" + this.state.inviteId} <IconButton onClick={this.handleCopy}><CopyAllIcon /></IconButton>
                            </div>

                            <Button style={{margin: 10}} variant={"outlined"} onClick={this.handleClose}>
                                Open Quick Connect Session
                            </Button>

                        </div> : null
                    }

                </DialogContent>
                {!this.state.inviteId ?
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.createQuickConnectPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                    <Button variant={"outlined"} onClick={this.createSession}>{content.createQuickConnectPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                </DialogActions>
                : null}
            </Dialog>
        );
    }
}
