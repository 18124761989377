import React, {Component} from "react";
import {Divider, IconButton} from "@mui/material";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import StopIcon from "@mui/icons-material/Stop";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {content} from "../../globals/content";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TranslateIcon from "@mui/icons-material/Translate";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AddCircleIcon from "@mui/icons-material/AddCircle";


export default class LeftMenuTop extends Component {

    render() {
        return (<div style={{paddingTop: "10px"}}>

                <IconButton style={{ display: "none"}} aria-describedby={"tm_1"} variant="contained" onClick={this.props.toggleNotifications}>
                    <Badge color="secondary" badgeContent={7}>
                        <NotificationsIcon style={{color: "#444"}} />
                    </Badge>
                </IconButton>

                <div style={{fontSize: ".6rem", display: "none"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].notificationsSubtext}</div>

                <IconButton aria-describedby={"tm_2"} variant="contained" onClick={this.props.toggleAutoplay}>
                    {this.props.autoplay === "true" || this.props.autoplay === true ?
                        <VolumeUpIcon style={{color: "#758a00"}}/> :
                        <VolumeOffIcon style={{color: "#8a0000"}}/>
                    }
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].autoplaySubtext}</div>

                <Divider style={{marginTop: "5px", marginBottom: "5px"}} />

                <IconButton aria-describedby={"tm_3"} variant="contained" onClick={() => {
                    this.props.toggleLeftDrawer("left", true, "conversation")}
                }>
                    <QuestionAnswerIcon style={{color: "#444"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].groupSubtext}</div>

                <IconButton aria-describedby={"tm_4"} variant="contained" onClick={() => {
                    this.props.toggleLeftDrawer("left", true, "translate")
                }}>
                    <TranslateIcon style={{color: "#444"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].translateSubtext}</div>

                <IconButton style={{ }} aria-describedby={"tm_5"} variant="contained" onClick={() => {
                    this.props.toggleLeftDrawer("left", true, "shared")
                }}>
                    <InterpreterModeIcon style={{color: "#444"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].sharedSubtext}</div>

                <IconButton aria-describedby={"tm_6"} variant="contained" onClick={() => {
                    this.props.toggleLeftDrawer("left", true, "quickConnect")
                    //setQuickConnectOpen(true)
                }}>
                    <QrCodeScannerIcon style={{color: "#444"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].quickConnectSubtext}</div>

                <IconButton aria-describedby={"tm_7"} variant="contained" onClick={() => {
                    this.props.setCreateChatOpen(true)
                }}>
                    <AddCircleIcon style={{color: "#444"}} />
                </IconButton>
                <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].addSubtext}</div>

            </div>
        );
    }
}
