import React, {Component} from "react";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import Flag from 'react-world-flags';
import eventBus from "../../helpers/eventBus";
export default class LanguageSelector extends Component {
    state = {
        languageList: [
            {languageCode: "en-US", languageName: "English", flag: "us", direction: "lr"},
            {languageCode: "en-GB", languageName: "English", flag: "gb", direction: "lr"},
            {languageCode: "es-ES", languageName: "Spanish", flag: "es", direction: "lr"},
            {languageCode: "es-MX", languageName: "Spanish", flag: "mx", direction: "lr"},
            {languageCode: "fr-FR", languageName: "French", flag: "fr", direction: "lr"},
            {languageCode: "it-IT", languageName: "Italian", flag: "it", direction: "lr"},
            {languageCode: "de-DE", languageName: "German", flag: "de", direction: "lr"},
            {languageCode: "hi-IN", languageName: "Hindi", flag: "in", direction: "lr"},
            {languageCode: "cmn-CN", languageName: "Chinese", flag: "cn", direction: "lr"},
            {languageCode: "ar-SA", languageName: "Arabic", flag: "sa", direction: "rl"},
            {languageCode: "ar-IQ", languageName: "Arabic", flag: "iq", direction: "rl"},
            {languageCode: "so-SO", languageName: "Somali", flag: "so", direction: "lr"},
            {languageCode: "pt-BR", languageName: "Portuguese", flag: "br", direction: "lr"},
            {languageCode: "pt-PT", languageName: "Portuguese", flag: "pt", direction: "lr"},
            {languageCode: "id-ID", languageName: "Indonesian", flag: "id", direction: "lr"},
            {languageCode: "vi-VN", languageName: "Vietnamese", flag: "vn", direction: "lr"},
            {languageCode: "ko-KR", languageName: "Korean", flag: "kr", direction: "lr"},
            {languageCode: "ja-JP", languageName: "Japanese", flag: "jp", direction: "lr"},
            {languageCode: "fil-PH", languageName: "Filipino", flag: "ph", direction: "lr"},
            //{languageCode: "am_ET", languageName: "Amharic", flag: "et", direction: "lr"},
            //{languageCode: "ckb-IQ", languageName: "Kurdish", flag: "iq", direction: "lr"},
            //{languageCode: "ne-NP", languageName: "Nepali", flag: "ne", direction: "lr"},

        ],
        selectedLanguage: ""
    }

    componentDidMount() {
        console.log("language list loaded");
        if (!this.state.selectedLanguage && !localStorage.getItem("siteLanguage")){
            this.setState({selectedLanguage: "en-US"})
            localStorage.setItem("siteLanguage", "en-US");
            this.forceUpdate();
        } else {

            let selectedLanguage = localStorage.getItem("siteLanguage");
            if (this.props.language){
                selectedLanguage = this.props.language;
            }

            this.setState({selectedLanguage: selectedLanguage || localStorage.getItem("siteLanguage")})
        }
    }

    render() {
        //console.log(this.props)
        return (
            <div style={{
                display: "flex", flexDirection: "row",
                flexWrap: "wrap", gap: 10,
                justifyContent: "space-between", margin: "0 auto"
            }}>
                <FormControl sx={{ m: 0, width: this.props.size === "compact" ? 180 : 300 }} size={this.props.size === "compact" ? "small" : null}>
                    <InputLabel htmlFor="demo-multiple-name-label">{this.props.label}</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={this.state.selectedLanguage}
                        input={<OutlinedInput label="Language" />}
                        onChange={(e) => {
                            if (this.props.context && this.props.context === "siteLanguage"){
                                localStorage.setItem("siteLanguage", e.target.value);
                                eventBus.dispatch("updateSiteLanguage", {  });
                            }
                            this.props.setLanguage(e.target.value);
                            this.setState({selectedLanguage: e.target.value})
                        }}
                        style={{textAlign: "left"}}

                    >
                        {this.state.languageList.map((language) => (
                            <MenuItem
                                style={{padding: "3px"}}
                                key={language.languageCode + "_" + language.languageName}
                                value={language.languageCode}
                            >
                                <div style={{display: "flex", flexDirection: "row"}}><Flag code={language.flag} style={{maxHeight: "20px", maxWidth: "30px", marginRight: "10px", marginLeft: "10px", borderRadius: "3px"}} /> {language.languageName}</div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}
