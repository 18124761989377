import React, {Component} from "react";
import {FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import TranslateIcon from '@mui/icons-material/Translate';
import {content} from "../../globals/content";

export default class ChatOptionSelector extends Component {
    state = {
        conversationList: [
            //{conversationCode: "join", name: "Join a Conversation with ID", image: InputIcon},
            {conversationCode: "remote", name: "New Remote Conversation", image: CoPresentIcon},
            //{conversationCode: "shared", name: "New Shared Screen Conversation", image: InterpreterModeIcon},
            {conversationCode: "personal", name: "Personal Translator", image: TranslateIcon}
        ],
        conversationType: ""
    }

    componentDidMount() {
        console.log("mount", this.props.preselect)
        if (this.props.preselect && this.props.preselect === "translate") {
            this.setState({conversationType: "personal"});
            this.props.setConversationInvoke("personal");
        }
        if (this.props.preselect && this.props.preselect === "conversation") {
            this.setState({conversationType: "remote"});
            this.props.setConversationInvoke("remote");
        }
        if (this.props.preselect && this.props.preselect === "shared") {
            this.setState({conversationType: "shared"});
            this.props.setConversationInvoke("shared");
        }

    }


    render() {
        return (
            <div style={{

                 width: 300, margin: "0 auto"
            }}>
                <FormControl sx={{ m: 0, width: 300 }}>
                    <InputLabel htmlFor="demo-multiple-name-label">{content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeLabel}
                    </InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={this.state.conversationType}
                        input={<OutlinedInput label="Language" />}
                        onChange={(e) => {
                            this.setState({conversationType: e.target.value})
                            this.props.setConversationInvoke(e.target.value);
                        }}
                        style={{textAlign: "left"}}

                    >
                        <MenuItem
                            key={"remote_New Remote Conversation"}
                            value={"remote"}
                        >
                            <div style={{display: "flex", flexDirection: "row"}}><CoPresentIcon style={{maxHeight: "20px", maxWidth: "30px", marginRight: "10px", marginLeft: "10px", borderRadius: "3px"}} />
                                {
                                    content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeGroupChat
                                }
                            </div>
                        </MenuItem>

                        <MenuItem
                            key={"personal_Personal Translator"}
                            value={"personal"}
                        >
                            <div style={{display: "flex", flexDirection: "row"}}><TranslateIcon style={{maxHeight: "20px", maxWidth: "30px", marginRight: "10px", marginLeft: "10px", borderRadius: "3px"}} />
                                {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeTranslationChat}
                            </div>
                        </MenuItem>

                        <MenuItem
                            key={"shared_Shared Screen Conversation"}
                            value={"shared"}
                        >
                            <div style={{display: "flex", flexDirection: "row"}}><TranslateIcon style={{maxHeight: "20px", maxWidth: "30px", marginRight: "10px", marginLeft: "10px", borderRadius: "3px"}} />
                                {content.createChatPanel[localStorage.getItem("siteLanguage") || "en-US"].selectChatTypeSharedScreenChat}
                            </div>
                        </MenuItem>

                    </Select>
                </FormControl>
            </div>
        )
    }
}
